import { useSelector } from "react-redux";
import UserDetails from "././Users/UserDetails";

function UserInformation() {
  var userInfo = useSelector((state) => {
    return state.ordredItem.userInfo;
  });

  if (userInfo.userKey === -1) {
    return <h3 className="text-start elem-header">Please sign in.</h3>;
  } else {
    return <UserDetails></UserDetails>;
  }
}

export default UserInformation;
