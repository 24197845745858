import { left } from "@popperjs/core";

function Contact(props) {
  return (
    <div className="contact_Wraper">
      <div className="elem-header" style={{ textAlign: left }}>
        Contact Us
      </div>
      <div style={{ textAlign: left, marginBottom: 30 }}>
        For Franchise and Catering, please send email to loobapbap@gmail.com
      </div>

      <div className="elem-header" style={{ textAlign: left }}>
        Store Hours
      </div>
      <div className="hours">
        <tbody>
          <tr className="K7Ltle">
            <td className="SKNSIb">Sunday</td>
            <td>11:30 a.m.–9:00 p.m.</td>
          </tr>
          <tr>
            <td className="SKNSIb">Monday</td>
            <td>11:30 a.m.–9:00 p.m.</td>
          </tr>
          <tr>
            <td className="SKNSIb">Tuesday</td>
            <td>11:30 a.m.–9:00 p.m.</td>
          </tr>
          <tr>
            <td className="SKNSIb">Wednesday</td>
            <td>11:30 a.m.–9:00 p.m.</td>
          </tr>
          <tr>
            <td className="SKNSIb">Thursday</td>
            <td>11:30 a.m.–9:00 p.m.</td>
          </tr>
          <tr>
            <td className="SKNSIb">Friday</td>
            <td>11:30 a.m.–9:00 p.m.</td>
          </tr>
          <tr>
            <td className="SKNSIb">Saturday</td>
            <td>11:30 a.m.–9:00 p.m.</td>
          </tr>
        </tbody>
      </div>
    </div>
  );
}

export default Contact;
