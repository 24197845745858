import { useSelector, useDispatch } from "react-redux";
import InputForm from "../Utilities/InputForm";
import { UpdateUserInfo } from "../Slices/OrderedItemsSlice";
import ContactWithVerification from "../Utilities/ContactWithVerification";
import Logger from "../Utilities/Logger";
import AppConsts from "../App_Consts";
import { useCookies } from "react-cookie";

function UserInfoInputs() {
  const [cookies, setCookie] = useCookies(["name"]);
  const dispatch = useDispatch();
  var userInfo = useSelector((state) => {
    return state.ordredItem.userInfo;
  });

  var verificationInfo = useSelector((state) => {
    return state.ordredItem.verificationInfo;
  });

  if (userInfo.userKey === -1) {
    return <h3 className="text-start elem-header">Please sign in.</h3>;
  }

  const UpdateName = async () => {
    var fNameElem = document.getElementById("fName");
    var lNameElem = document.getElementById("lName");
    const body = JSON.stringify({
      UserKey: userInfo.userKey,
      fName: fNameElem.value,
      lName: lNameElem.value,
    });
    let fName = fNameElem.value;
    let lName = lNameElem.value;
    const respone = await fetch(
      AppConsts.API_URL_BASE + "/Auth/UpdateUserName",
      {
        method: "POST",
        body,
      }
    );
    if (respone.ok) {
      dispatch(
        UpdateUserInfo({
          userId: userInfo.userId,
          userKey: userInfo.userKey,
          contact: userInfo.contact,
          fName: fName,
          lName: lName,
        })
      );

      setCookie("unserInformation", {
        userId: userInfo.userId,
        userKey: userInfo.userKey,
        contact: userInfo.contact,
        fName: fName,
        lName: lName,
      });
      window.location.href = "/UserInfo";
    }
  };

  var contactWidth = "col-lg-10";
  var verifficationRequired = true;

  return (
    <div className="row">
      <div className="col-6">
        <form id="userInformation" className="col-lg-12">
          <div className="col-lg-6" style={{ display: "inline-block" }}>
            <InputForm
              inputLabel="First Name"
              inputId="fName"
              inputValue={userInfo.fName}
            />
          </div>
          <div className="col-lg-6" style={{ display: "inline-block" }}>
            <InputForm
              inputLabel="Last Name"
              inputId="lName"
              inputValue={userInfo.lName}
            />
          </div>
        </form>
        <form id="contactInformation" className={contactWidth}>
          <ContactWithVerification
            inputLabel="Contact Number"
            inputId="contact"
            isVerificationRequired={verifficationRequired}
            contactNumber={userInfo.contact}
          />
        </form>
        <div className="col-3 text-start">
          <button
            type="button"
            className="btn btn-primary"
            onClick={UpdateName}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
}

export default UserInfoInputs;
