import { useSelector, useDispatch } from "react-redux";
import {
  incrementQuantity,
  decrementQuantity,
  addOrder,
  setIsShowOption,
  updateStoreCreditCategory,
  updateCheckedOptionItems,
} from "../Slices/OrderedItemsSlice";
import React, { useState, useEffect } from "react";
import Logger from "../Utilities/Logger";
import { LoadStoreCreditCategory } from "./MenuUtility";

function MenuItemDetail(props) {
  const { catId, menuItemInfo } = props;
  const dispatch = useDispatch();

  const storeCreditCatFromSlice = useSelector((state) => {
    return state.ordredItem.storeCreditCategory;
  });

  const [storeCreditCat, setstoreCreditCat] = useState(storeCreditCatFromSlice);

  const currentCount = useSelector((state) => {
    let count = 0;
    if (state.ordredItem.currentCounts[menuItemInfo.PKEY]) {
      count = state.ordredItem.currentCounts[menuItemInfo.PKEY];
    }
    return count;
  });

  const optionItems = useSelector((state) => {
    return state.ordredItem.menuOptions[menuItemInfo.PKEY];
  });

  const doLoadStoreCreditCategory = async () => {
    var creditCat = await LoadStoreCreditCategory();
    dispatch(updateStoreCreditCategory({ storeCreditCategory: creditCat }));
    setstoreCreditCat(creditCat);
  };

  if (storeCreditCat == -1) {
    doLoadStoreCreditCategory();
  }

  const LaunchMenuOptions = () => {
    dispatch(
      setIsShowOption({
        isShowOption: true,
        optionMenuKey: menuItemInfo.PKEY,
      })
    );
  };

  const isSouldOut = menuItemInfo.IsSoldOut === "True";
  const isStoreCredit = menuItemInfo.ID_CAT == storeCreditCat;
  const isOptionDisable = isSouldOut || isStoreCredit;

  Logger.Log("storeCreditCat:", storeCreditCat);
  Logger.Log("menuItemInfo", menuItemInfo);
  Logger.Log("isSoldOut", isSouldOut);
  Logger.Log("isOptionDisable", isOptionDisable);

  var souldOut = "";
  if (isSouldOut) {
    souldOut = (
      <label className="fs-3" style={{ color: "red", opacity: "0.5" }}>
        Soult Out
      </label>
    );
  }

  const AddClick_Handler = () => {
    if (isStoreCredit) {
      dispatch(
        updateCheckedOptionItems({
          menuKey: menuItemInfo.PKEY,
          optionKey: optionItems[0].PKEY,
          checkedState: true,
        })
      );
    }

    dispatch(addOrder({ menuKey: menuItemInfo.PKEY, catId }));
  };

  return (
    <div className="MenuItem">
      <h4 className="mt-2">{menuItemInfo.NAME}</h4>
      <h5>{menuItemInfo.DESCRIPTION}</h5>
      <h4 className="mt-2">${menuItemInfo.PRICE}</h4>
      {souldOut}
      <div className="OptionButtonGroup CountButtonGroup btn-group">
        <button
          type="button"
          onClick={LaunchMenuOptions}
          className="btn btn-secondary"
          disabled={isOptionDisable}
        >
          Option
        </button>
      </div>
      <div className="CountButtonGroup btn-group">
        <button
          type="button"
          onClick={() => dispatch(decrementQuantity(menuItemInfo.PKEY))}
          className="btnLeft btnCountControl btn btn-info"
          disabled={isSouldOut}
        >
          -
        </button>
        <span className="CountInfo">{currentCount}</span>
        <button
          type="button"
          onClick={() => dispatch(incrementQuantity(menuItemInfo.PKEY))}
          className="btnRight btnCountControl btn btn-info"
          disabled={isSouldOut}
        >
          +
        </button>
      </div>
      <div className="AddOrderButton CountButtonGroup btn-group">
        <button
          type="button"
          onClick={AddClick_Handler}
          className="btn btn-primary"
          disabled={isSouldOut}
        >
          Add
        </button>
      </div>
    </div>
  );
}

export default MenuItemDetail;
