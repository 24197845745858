import { useSelector, useDispatch } from "react-redux";
import {
  incrementQuantityInCart,
  decrementQuantityInCart,
  generateOrderedKey,
} from "../Slices/OrderedItemsSlice";
// import { useCookies } from "react-cookie";

import { CalculateTotal, IsVaildItem, FindOptionItem } from "./CheckoutUtility";
import { auto } from "@popperjs/core";
import Logger from "../Utilities/Logger";

function CheckoutBody(props) {
  const { IsViewOrderDetails } = props;
  Logger.Log("CheckoutBody IsViewOrderDetails:", IsViewOrderDetails);

  const itemsInCart = useSelector((state) => {
    return state.ordredItem.itemsInCart;
  });
  const allData = useSelector((state) => {
    return state.ordredItem.data;
  });
  const menuOptions = useSelector((state) => {
    return state.ordredItem.menuOptions;
  });
  const storeCreditCatFromSlice = useSelector((state) => {
    return state.ordredItem.storeCreditCategory;
  });

  const dispatch = useDispatch();

  var totalResult = CalculateTotal(
    itemsInCart,
    allData,
    menuOptions,
    storeCreditCatFromSlice
  );

  var subTotal = totalResult.total;
  var tax = totalResult.taxTotal;
  var grandTotal = totalResult.total + totalResult.taxTotal;
  var orderedItems = [];
  for (let itemKey in itemsInCart) {
    let item = itemsInCart[itemKey];
    if (IsVaildItem(item, allData)) {
      orderedItems.push(item);
    }
  }

  if (!orderedItems || orderedItems.length === 0) {
    return <div className="CheckoutBody NoItems">No Items in cart</div>;
  }
  let bodyMaxHeight = window.innerHeight * 0.7;

  const orderControl = (menuItemInfo, optionSelected, item, isDetailsView) => {
    if (isDetailsView === "true") return <div className="col-4"></div>;
    else
      return (
        <div className="col-4">
          <div className="btn-group cartBtn col-3 ControlBtnGrp">
            <button
              type="button"
              onClick={() =>
                dispatch(
                  decrementQuantityInCart(
                    generateOrderedKey(menuItemInfo.PKEY, optionSelected)
                  )
                )
              }
              className="btnLeft btnCountControl btn btn-info"
            >
              -
            </button>
            <span className="CountInfo">{item.count}</span>
            <button
              type="button"
              onClick={() =>
                dispatch(
                  incrementQuantityInCart(
                    generateOrderedKey(menuItemInfo.PKEY, optionSelected)
                  )
                )
              }
              className="btnRight btnCountControl btn btn-info"
            >
              +
            </button>
          </div>
        </div>
      );
  };

  return (
    <div className="CheckoutBody">
      <div
        className="contentsBody"
        style={{ maxHeight: bodyMaxHeight, overflow: auto }}
      >
        {orderedItems.map((item) => {
          var menuItemInfo = allData[item.catId].items.find(
            (element) => element.PKEY === item.menuKey
          );
          let optionSelected = [];
          if (item.optionsSelected !== undefined) {
            optionSelected = item.optionsSelected;
          }
          var price = parseFloat(menuItemInfo.PRICE) * item.count;
          return (
            <div className="container-fluid" key={item.menuKey}>
              <div className="row">
                <h4 className="cartMenuName col-5">{menuItemInfo.NAME}</h4>
                {orderControl(
                  menuItemInfo,
                  optionSelected,
                  item,
                  IsViewOrderDetails
                )}
                <h4 className="cartPrice col-3">${price.toFixed(2)}</h4>
              </div>
              <div className="OptionsSelected row">
                {optionSelected.map((selecteOptionKey) => {
                  let optionItem = FindOptionItem(
                    selecteOptionKey,
                    menuItemInfo.PKEY,
                    menuOptions
                  );
                  if (optionItem) {
                    let optionPrice = optionItem.PRICE * item.count;
                    if (item.catId == storeCreditCatFromSlice) {
                      optionPrice = 0;
                    }
                    return (
                      <div className="row">
                        <span className="col-9">- {optionItem.NAME}</span>
                        <span className="col-2 px-5">${optionPrice}</span>
                      </div>
                    );
                  }
                  return <div></div>;
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className="CheckOutInfoContainer mt-4">
        <div className="row">
          <span className="col-3">Subtotal:</span>
          <span className="col-3">${subTotal.toFixed(2)}</span>
        </div>
        <div className="row">
          <span className="col-3">Tax:</span>
          <span className="col-3">${tax.toFixed(2)}</span>
        </div>
        <div className="row">
          <span className="col-3">Grand Total:</span>
          <span className="col-3">${grandTotal.toFixed(2)}</span>
        </div>
      </div>
    </div>
  );
}

export default CheckoutBody;
