import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AppConsts from "../App_Consts";
import Logger from "../Utilities/Logger";
import { auto } from "@popperjs/core";

function StoreCreditTransactionDetails() {
  var msg =
    "Please click View Transaction button to view transactions in last 30 days.";

  var userInfo = useSelector((state) => {
    return state.ordredItem.userInfo;
  });
  const [transactions, setTransactions] = useState([]);
  Logger.Log("RetrieveTranstions userinfo", userInfo);

  useEffect(() => {
    RetrieveCurrentStoreCreditBalance();
  }, []);

  const [currentStoreCreditBalnace, setcurrentStoreCreditBalnace] = useState(
    []
  );

  const RetrieveCurrentStoreCreditBalance = async () => {
    const body = JSON.stringify({
      UserKey: userInfo.userKey,
    });
    const respone = await fetch(
      AppConsts.API_URL_BASE + "/StoreCredit/GetCurrentStoreCreditBalance",
      {
        method: "POST",
        body,
      }
    );
    let jsonData = await respone.json();
    Logger.Log(
      "RetrieveTranstions RetrieveCurrentStoreCreditBalance",
      jsonData
    );
    setcurrentStoreCreditBalnace(jsonData);
  };

  const RetrieveTranstions = async () => {
    const body = JSON.stringify({
      UserKey: userInfo.userKey,
    });

    const respone = await fetch(
      AppConsts.API_URL_BASE + "/StoreCredit/GetStoreCreditTransactionDetails",
      {
        method: "POST",
        body,
      }
    );
    let jsonData = await respone.json();
    Logger.Log("RetrieveTranstions newData", jsonData);
    let newData = [...jsonData];

    setTransactions(newData);
  };
  var balanceMsg = " ";
  if (userInfo.contact === "") {
    balanceMsg = (
      <div className="text-danger">
        Please register your contact number to see store credit balance by
        clicking Edit button.
      </div>
    );
  }

  return (
    <div className="StoreCreditTransactionDetails col-12 text-start">
      <div className="Balancd-Wrapper col-6 text-start">
        Your current store credit balance:{balanceMsg}
        <h2 className="text-primary">${currentStoreCreditBalnace}</h2>
      </div>
      <div className="col-12">
        <label>{msg}</label>
        <button
          type="button"
          className="ms-3 btn btn-primary"
          onClick={RetrieveTranstions}
        >
          View Transaction
        </button>
      </div>
      <div
        className="TransactionDetails"
        style={{ maxHeight: 400, overflowY: auto }}
      >
        {transactions.map((item) => {
          let dataPair = item.split(",");
          let issueDate = dataPair[0];
          let amountData = "";
          let amount = Number(dataPair[1]);
          Logger.Log("RetrieveTranstions amount", amount);
          if (amount < 0) {
            amount *= -1;
            amountData = (
              <div className="col-6" style={{ display: "inline-flex" }}>
                <div className="col-6 text-start text-danger">Used</div>
                <div className="col-6 text-start text-danger">${amount}</div>
              </div>
            );
          } else {
            amountData = (
              <div className="col-6" style={{ display: "inline-flex" }}>
                <div className="col-6 text-start text-success">Funded</div>
                <div className="col-6 text-start text-success">${amount}</div>
              </div>
            );
          }

          return (
            <div className="col-12" style={{ display: "inline-flex" }}>
              <div className="col-6 text-start">{issueDate}:</div>
              {amountData}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default StoreCreditTransactionDetails;
