import React from "react";
import { NavLink } from "react-router-dom";

class CategoryMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CatName: props.CatName,
    };
  }

  render() {
    var targetUrl = `${this.state.CatName}`;
    if (this.state.CatName === "Menu") {
      targetUrl = "/Menu/1";
    }
    return (
      <nav className="navbar navbar-light bg-light CatItem">
        <NavLink className="navbar-brand" id={this.props.Id} to={targetUrl}>
          <span className="p-3">{this.props.CatDisplay}</span>
        </NavLink>
      </nav>
    );
  }
}

export default CategoryMenu;
