import AppConsts from "../App_Consts";

const LoadMenuItem = async (catId) => {
  if (catId == undefined) return [];

  const encodedStoreId = encodeURIComponent(AppConsts.STORE_ID);
  let targetUrl =
    AppConsts.API_URL_BASE +
    "/menu?aCategory=" +
    catId +
    "&storeId=" +
    encodedStoreId;
  let respone = await fetch(targetUrl);
  let jsonData = await respone.json();
  let rowData = [];
  for (let idx = 0; idx < jsonData.length; idx++) {
    let jsonItem = JSON.parse(jsonData[idx].menu);
    rowData[rowData.length] = jsonItem;
  }

  return rowData;
};

export const LoadMenuOptionItems = async (menuKey) => {
  const encodedStoreId = encodeURIComponent(AppConsts.STORE_ID);
  let targetUrl =
    AppConsts.API_URL_BASE +
    "/Menu/GetSubMenuItems?aMenuKey=" +
    menuKey +
    "&storeId=" +
    encodedStoreId;
  let respone = await fetch(targetUrl);
  let jsonData = await respone.json();
  let rowData = [];

  for (let idx = 0; idx < jsonData.length; idx++) {
    let jsonItem = JSON.parse(jsonData[idx]);
    rowData[rowData.length] = jsonItem;
  }

  return rowData;
};

export const LoadStoreCreditCategory = async () => {
  const encodedStoreId = encodeURIComponent(AppConsts.STORE_ID);
  let targetUrl =
    AppConsts.API_URL_BASE +
    "/Menu/GetStoreCreditCategory?storeId=" +
    encodedStoreId;
  let respone = await fetch(targetUrl);
  let jsonData = await respone.json();

  return jsonData;
};

export default LoadMenuItem;
