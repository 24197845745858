import { useSelector, useDispatch } from "react-redux";
import StoreCreditTransactionDetails from "../StoreCredit/StoreCreditTransactionDetails";
import { NavLink } from "react-router-dom";
import Logger from "../Utilities/Logger";

function UserDetails() {
  const dispatch = useDispatch();
  var userInfo = useSelector((state) => {
    return state.ordredItem.userInfo;
  });

  var fName = userInfo.fName;
  var lName = userInfo.lName;
  var contact = userInfo.contact;
  var targetUrl = "/UserInfo/Edit";

  Logger.Log("UserDetails userInfo:", userInfo);
  return (
    <div className="col-lg-6 col-sm-12">
      <div className="col-12 text-start">
        <label>First Name: {fName}</label>
      </div>
      <div className="col-12 text-start">
        <label>Last Name: {lName}</label>
      </div>
      <br />
      <div className="col-12 text-start">Contact: {contact}</div>
      <br />

      <div className="col-12 text-start">
        <NavLink className="btn btn-primary" to={targetUrl}>
          <span className="p-3">Edit</span>
        </NavLink>
      </div>
      <br />
      <StoreCreditTransactionDetails></StoreCreditTransactionDetails>
    </div>
  );
}

export default UserDetails;
