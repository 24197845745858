import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import CupBap from "./images/CupBap.png";
import KimBap from "./images/KimBap.png";
import Sandwich from "./images/Sandwich.png";
import StoreCredit from "./images/credit-card-svgrepo-com.svg";
import Soda from "./images/soda-beverage-svgrepo-com.svg";
import RiceCake from "./images/riceCake.png";
import Starter from "./images/starter-svgrepo-com.svg";
import AppConsts from "./App_Consts";
import MenuItemsDetails from "./Menu/MenuItemsDetails";

function Menu(props) {
  const [categories, setCategory] = useState([]);

  useEffect(() => {
    loadCategory();
  }, []);

  const defaultCatId = props.defaultCatId;

  const loadCategory = async () => {
    let targetUrl = AppConsts.API_URL_BASE + "/menu/GetMenuCategory";
    let respone = await fetch(targetUrl);
    let jsonData = await respone.json();
    let newData = [...jsonData];

    setCategory(newData);
  };

  return (
    <div className="Menu_wrap">
      <div className="MenuItem_wrap">
        {categories.map((item) => {
          let jsonItem = JSON.parse(item);

          let menuPath = null;
          if (jsonItem.CAT_DESC === "CupBap") {
            menuPath = CupBap;
          } else if (jsonItem.CAT_DESC === "KimBap") {
            menuPath = KimBap;
          } else if (jsonItem.CAT_DESC === "Sandwich") {
            menuPath = Sandwich;
          } else if (jsonItem.CAT_DESC === "Beverage") {
            menuPath = Soda;
          } else if (jsonItem.CAT_DESC === "Appetizer") {
            menuPath = Starter;
          } else if (jsonItem.CAT_DESC === "Spicy Rice Cakes") {
            menuPath = RiceCake;
          } else if (jsonItem.CAT_DESC === "Store Credit") {
            menuPath = StoreCredit;
          }

          return (
            <nav className="navbar" key={jsonItem.PKEY}>
              <NavLink
                className="navbar-brand"
                id={jsonItem.PKEY}
                to={"/Menu/" + jsonItem.PKEY}
              >
                <img src={menuPath} style={{ width: 35 }}></img>
                <span className="p-3">{jsonItem.CAT_DESC}</span>
              </NavLink>
            </nav>
          );
        })}
      </div>
      <div className="row" style={{ marginTop: 25 }}>
        <MenuItemsDetails defaultCatId={defaultCatId} />
      </div>
    </div>
  );
}

export default Menu;
