import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsShowCart,
  registerVerificationToken,
} from "../Slices/OrderedItemsSlice";
import CheckoutBody from "../Checkout/CheckoutBody";
import { useCookies } from "react-cookie";
import OrderedItemStore from "../Slices/OrderedItemStore";
import { useNavigate } from "react-router-dom";

function ModalItemsInCart() {
  const [checkoutCookies, setCheckoutCookie] = useCookies("Checkout");

  const isShow = useSelector((state) => {
    return state.ordredItem.isShowCartItems;
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const HandleModalHide = () => {
    dispatch(setIsShowCart(false));
  };

  const NagivateToCheckout = () => {
    let state = OrderedItemStore.getState();
    setCheckoutCookie("ItemsInCart", state.ordredItem.itemsInCart, {
      path: "/Checkout",
      maxAge: 20 * 60,
    });
    setCheckoutCookie("allMenuData", state.ordredItem.data, {
      path: "/Checkout",
      maxAge: 20 * 60,
    });
    dispatch(
      registerVerificationToken({
        verificationToken: "",
        contact: "",
      })
    );
    HandleModalHide();
    navigate("/Checkout");
  };

  return (
    <Modal size="lg" show={isShow} onHide={HandleModalHide}>
      <Modal.Body>
        <CheckoutBody IsViewOrderDetails="false" />
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-primary" onClick={HandleModalHide}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={NagivateToCheckout}>
          Check out
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalItemsInCart;
