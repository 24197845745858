import Logger from "../Utilities/Logger";

export const IsVaildItem = (item, allData) => {
  return (
    item && item.count > 0 && allData[item.catId] && allData[item.catId].items
  );
};

export const FindOptionItem = (optionKey, menuKey, menuOptions) => {
  let optionItem = null;
  menuOptions[menuKey].forEach((option) => {
    if (option.PKEY === optionKey) {
      optionItem = option;
      return;
    }
  });

  return optionItem;
};

export const CalculateItemTotal = (
  item,
  allData,
  menuOptions,
  storeCreditCat
) => {
  let subtotal = 0;
  if (!IsVaildItem(item, allData)) return subtotal;

  let info = allData[item.catId].items.find(
    (element) => element.PKEY === item.menuKey
  );
  if (info) {
    subtotal += parseFloat(info.PRICE) * item.count;
    Logger.Log("CalculateItemTotal item.catId:", item.catId);
    if (item.optionsSelected && item.catId != storeCreditCat) {
      item.optionsSelected.forEach((optionKey) => {
        let optionItem = FindOptionItem(optionKey, item.menuKey, menuOptions);
        if (optionItem) {
          subtotal += parseFloat(optionItem.PRICE) * item.count;
        }
      });
    }
  }

  return subtotal;
};

export const CalculateTotal = (
  itemsInCart,
  allData,
  menuOptions,
  storeCreditCat
) => {
  let result = {};
  result.total = 0;
  result.taxTotal = 0;
  let keys = Object.keys(itemsInCart);
  keys.forEach((itemKey) => {
    let cartItem = itemsInCart[itemKey];
    let itemAmount = CalculateItemTotal(
      cartItem,
      allData,
      menuOptions,
      storeCreditCat
    );
    result.total += itemAmount;
    if (cartItem.catId != storeCreditCat) {
      result.taxTotal += itemAmount * 0.13;
    }
  });

  return result;
};

export const HasStoreCredit = (itemsInCart, storeCreditCat) => {
  let result = false;
  let keys = Object.keys(itemsInCart);
  keys.forEach((itemKey) => {
    let cartItem = itemsInCart[itemKey];
    if (cartItem.count > 0 && cartItem.catId == storeCreditCat) {
      result = true;
      return;
    }
  });

  return result;
};
