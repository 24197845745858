import CartInfo from "../ShoppingCart/CartInfo";
import Login from "./Login";
import CreateAccount from "./CreateAccount";
import ForgotPassword from "./ForgotPassword";
import { setIsShowLogin } from "../Slices/OrderedItemsSlice";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { UpdateUserInfo, signOut } from "../Slices/OrderedItemsSlice";

function UserInfo() {
  var dispatch = useDispatch();
  const [cookies, removeCookie] = useCookies(["unserInformation"]);

  const userInfo = useSelector((state) => {
    return state.ordredItem.userInfo;
  });

  const HandleLogin = () => {
    if (userInfo.userKey === -1) {
      dispatch(setIsShowLogin(true));
    } else {
      //singn out
      removeCookie("unserInformation");
      removeCookie("verification");
      dispatch(signOut());
    }
  };

  if (
    userInfo.userKey === -1 &&
    cookies.unserInformation !== undefined &&
    cookies.unserInformation !== "undefined"
  ) {
    dispatch(
      UpdateUserInfo({
        userId: cookies.unserInformation.userId,
        userKey: cookies.unserInformation.userKey,
        contact: cookies.unserInformation.contact,
        fName: cookies.unserInformation.fName,
        lName: cookies.unserInformation.lName,
      })
    );
  }

  var btnLabel = userInfo.userKey === -1 ? "Sign In" : "Sing Out";

  return (
    <div className="input-group">
      <Login />
      <CreateAccount />
      <ForgotPassword />
      <div className="UserInfoContainer input-group">
        <div className="pt-2">{userInfo.userId}</div>
        <button
          type="button"
          className="btn btn-link ml-3"
          onClick={HandleLogin}
        >
          {btnLabel}
        </button>
      </div>
      <CartInfo />
    </div>
  );
}

export default UserInfo;
